import { render, staticRenderFns } from "./facebook.vue?vue&type=template&id=4f0efdd6&"
var script = {}


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Volumes/Development/2020/invoicing/crater-20201222/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4f0efdd6')) {
      api.createRecord('4f0efdd6', component.options)
    } else {
      api.reload('4f0efdd6', component.options)
    }
    module.hot.accept("./facebook.vue?vue&type=template&id=4f0efdd6&", function () {
      api.rerender('4f0efdd6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/components/icon/facebook.vue"
export default component.exports
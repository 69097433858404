<template>
  <div class="graph-container">
    <canvas id="graph" ref="graph" />
  </div>
</template>

<script>
import Chart from 'chart.js'
import { mapGetters } from 'vuex'

export default {
  props: {
    labels: {
      type: Array,
      require: true,
      default: Array
    },
    values: {
      type: Array,
      require: true,
      default: Array
    },
    projects: {
      type: Array,
      require: true,
      default: Array
    }
  },
  data() {
    return {
      colorScheme: [
        '#59C3C3',
        '#52489C',
        '#CAD2C5',
        '#84A98C',
        '#7F7EFF',
        '#A390E4',
        '#C69DD2',
        '#CC8B8C',
        '#C68866'
      ]
    }
  },
  computed: {
    ...mapGetters('company', ['defaultCurrency'])
  },

  watch: {
    labels(val) {
      this.update()
    }
  },

  mounted() {

    let self = this
    let context = this.$refs.graph.getContext('2d')
    let options = {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        enabled: true,
        callbacks: {
          label: function(tooltipItem, data) {
            const label = data.datasets[tooltipItem.datasetIndex].label || ''
            return label + ': ' + tooltipItem.value + ' h'
          }
        }
      }
      // legend: {
      //   display: false,
      // },
    }
    let data = {
      labels: this.labels,
      datasets: this.projects.map((project, i) => {
        return {
          label: project.project,
          fill: false,
          lineTension: 0.3,
          backgroundColor: 'rgba(230, 254, 249)',
          borderColor: this.colorScheme[i],
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: this.colorScheme[i],
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: this.colorScheme[i],
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 4,
          pointHitRadius: 10,
          data: project.values
        }
      })
    }

    this.myLineChart = new Chart(context, {
      type: 'line',
      data: data,
      options: options
    })
  },

  methods: {
    update() {
      this.myLineChart.data.labels = this.labels
      this.myLineChart.data.datasets = this.projects.map((project, i) => {
        return {
          label: project.project,
          fill: false,
          lineTension: 0.3,
          backgroundColor: 'rgba(230, 254, 249)',
          borderColor: this.colorScheme[i],
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: this.colorScheme[i],
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: this.colorScheme[i],
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 4,
          pointHitRadius: 10,
          data: project.values
        }
      })
      this.myLineChart.update({
        lazy: true
      })
    },

    beforeDestroy() {
      this.myLineChart.destroy()
    }
  }
}
</script>

<style scoped>
.graph-container {
  height: 300px;
}
</style>

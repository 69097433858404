var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-page",
    { staticClass: "item-create" },
    [
      _c(
        "sw-page-header",
        { staticClass: "mb-3", attrs: { title: _vm.pageTitle } },
        [
          _c(
            "sw-breadcrumb",
            { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
            [
              _c("sw-breadcrumb-item", {
                attrs: { to: "/admin/dashboard", title: _vm.$t("general.home") }
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: {
                  to: "/admin/projects",
                  title: _vm.$tc("projects.user", 2)
                }
              }),
              _vm._v(" "),
              _vm.$route.name === "projects.edit"
                ? _c("sw-breadcrumb-item", {
                    attrs: {
                      to: "#",
                      title: _vm.$t("projects.edit_user"),
                      active: ""
                    }
                  })
                : _c("sw-breadcrumb-item", {
                    attrs: {
                      to: "#",
                      title: _vm.$t("projects.new_user"),
                      active: ""
                    }
                  })
            ],
            1
          ),
          _vm._v(" "),
          _c("template", { slot: "actions" })
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "grid grid-cols-12" }, [
        _c("div", { staticClass: "col-span-12 md:col-span-8" }, [
          _c(
            "form",
            {
              attrs: { action: "" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.submitProject($event)
                }
              }
            },
            [
              _c(
                "sw-card",
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mb-4",
                      attrs: {
                        label: _vm.$t("projects.name"),
                        error: _vm.nameError,
                        required: ""
                      }
                    },
                    [
                      _c("sw-input", {
                        staticClass: "mt-2",
                        attrs: {
                          invalid: _vm.$v.formData.name.$error,
                          focus: "",
                          type: "text",
                          name: "name"
                        },
                        on: {
                          input: function($event) {
                            return _vm.$v.formData.name.$touch()
                          }
                        },
                        model: {
                          value: _vm.formData.name,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.formData,
                              "name",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formData.name"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mb-4",
                      attrs: {
                        label: _vm.$t("payments.customer"),
                        error: _vm.customerError,
                        required: ""
                      }
                    },
                    [
                      _c("sw-select", {
                        staticClass: "mt-1",
                        attrs: {
                          options: _vm.customers,
                          searchable: true,
                          "show-labels": false,
                          "allow-empty": false,
                          placeholder: _vm.$t("customers.select_a_customer"),
                          label: "name",
                          "track-by": "id"
                        },
                        model: {
                          value: _vm.customer,
                          callback: function($$v) {
                            _vm.customer = $$v
                          },
                          expression: "customer"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mb-4",
                      attrs: { label: _vm.$t("items.description") }
                    },
                    [
                      _c("sw-textarea", {
                        attrs: { rows: "2", name: "description" },
                        model: {
                          value: _vm.formData.description,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "description", $$v)
                          },
                          expression: "formData.description"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mt-6 mb-4" },
                    [
                      _c(
                        "sw-button",
                        {
                          staticClass: "flex justify-center w-full md:w-auto",
                          attrs: {
                            loading: _vm.isLoading,
                            variant: "primary",
                            type: "submit",
                            size: "lg"
                          }
                        },
                        [
                          !_vm.isLoading
                            ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                            : _vm._e(),
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.isEdit
                                  ? _vm.$t("projects.update_user")
                                  : _vm.$t("projects.save_user")
                              ) +
                              "\n            "
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
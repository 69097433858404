var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "grid gap-8 md:grid-cols-12" }, [
    _c(
      "div",
      { staticClass: "col-span-8 mt-12 md:col-span-4" },
      [
        _c(
          "div",
          { staticClass: "grid grid-cols-12" },
          [
            _c(
              "sw-input-group",
              {
                staticClass: "col-span-12 md:col-span-8",
                attrs: {
                  label: _vm.$t("reports.sales.date_range"),
                  error: _vm.dateRangeError
                }
              },
              [
                _c("sw-select", {
                  staticClass: "mt-2",
                  attrs: {
                    options: _vm.dateRange,
                    "allow-empty": false,
                    "show-labels": false
                  },
                  on: { input: _vm.onChangeDateRange },
                  model: {
                    value: _vm.selectedRange,
                    callback: function($$v) {
                      _vm.selectedRange = $$v
                    },
                    expression: "selectedRange"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "grid grid-cols-1 mt-6 md:gap-10 md:grid-cols-2" },
          [
            _c(
              "sw-input-group",
              {
                attrs: {
                  label: _vm.$t("reports.sales.from_date"),
                  error: _vm.fromDateError
                }
              },
              [
                _c("base-date-picker", {
                  staticClass: "mt-2",
                  attrs: {
                    invalid: _vm.$v.formData.from_date.$error,
                    "calendar-button": true,
                    "calendar-button-icon": "calendar"
                  },
                  on: {
                    change: function($event) {
                      return _vm.$v.formData.from_date.$touch()
                    }
                  },
                  model: {
                    value: _vm.formData.from_date,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "from_date", $$v)
                    },
                    expression: "formData.from_date"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "mt-5 md:mt-0",
                attrs: {
                  label: _vm.$t("reports.sales.to_date"),
                  error: _vm.toDateError
                }
              },
              [
                _c("base-date-picker", {
                  staticClass: "mt-2",
                  attrs: {
                    invalid: _vm.$v.formData.to_date.$error,
                    "calendar-button": true,
                    "calendar-button-icon": "calendar"
                  },
                  on: {
                    change: function($event) {
                      return _vm.$v.formData.to_date.$touch()
                    }
                  },
                  model: {
                    value: _vm.formData.to_date,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "to_date", $$v)
                    },
                    expression: "formData.to_date"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "grid grid-cols-12 mt-6 md:mt-8" },
          [
            _c(
              "sw-input-group",
              {
                staticClass: "col-span-12 md:col-span-8",
                attrs: { label: _vm.$t("reports.sales.report_type") }
              },
              [
                _c("sw-select", {
                  staticClass: "mt-2",
                  attrs: {
                    options: _vm.reportTypes,
                    "allow-empty": false,
                    "show-labels": false,
                    placeholder: _vm.$t("reports.sales.report_type")
                  },
                  on: { input: _vm.getInitialReport },
                  model: {
                    value: _vm.selectedType,
                    callback: function($$v) {
                      _vm.selectedType = $$v
                    },
                    expression: "selectedType"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "sw-button",
          {
            staticClass: "content-center hidden mt-0 w-md md:flex md:mt-8",
            attrs: { variant: "primary-outline", type: "submit" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.getReports()
              }
            }
          },
          [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("reports.update_report")) + "\n    "
            )
          ]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "col-span-8 mt-0 md:mt-12" }, [
      _c("iframe", {
        staticClass:
          "hidden w-full h-screen border-gray-100 border-solid rounded md:flex",
        attrs: { src: _vm.getReportUrl }
      }),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass:
            "flex items-center justify-center h-10 px-5 py-1 text-sm font-medium leading-none text-center text-white whitespace-no-wrap rounded md:hidden bg-primary-500",
          on: { click: _vm.viewReportsPDF }
        },
        [
          _c("document-text-icon"),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.$t("reports.view_pdf")))])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
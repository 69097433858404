<template>
  <div class="tax-type-modal">
    <form action="" @submit.prevent="submitTimeLogData">
      <div class="p-8 sm:p-6">
        <sw-input-group
          :label="$t('projects.time_logs.start_date')"
          variant="horizontal"
          class="mt-3"
          required
        >
          <base-date-picker
            v-model="formData.start_date"
            :calendar-button="true"
            calendar-button-icon="calendar"
          />
        </sw-input-group>

        <sw-input-group
          :label="$t('projects.time_logs.start_time')"
          class="mt-3"
          variant="horizontal"
          required
        >
          <div class="grid gap-6 sm:grid-col-1 md:grid-cols-2">
            <base-time-picker
              v-model="formData.start_time"          />

            <base-time-picker
              v-model="formData.end_time"          />
          </div>

          <!--            :set-value="defaultValue"-->
<!--            :invalid="$v.time.$error"-->
<!--            :placeholder="placeholder"-->
<!--            :tabindex="tabindex"-->
<!--            hide-clear-button-->
<!--            @input="onTimeSelect"-->
        </sw-input-group>

        <sw-input-group
          :label="$t('projects.time_logs.item')"
          class="mt-3"
          variant="horizontal"
          required
        >

          <sw-select
            v-model="item"
            :options="items"
            :searchable="true"
            :show-labels="false"
            :allow-empty="false"
            :placeholder="$t('customers.select_a_customer')"
            label="name"
            class="mt-1"
            track-by="id"
          />
        </sw-input-group>

        <sw-input-group
          :label="$t('projects.time_logs.hourly_rate')"
          class="mt-3"
          variant="horizontal"
          required
        >
          <sw-money
            v-model="formData.hourly_rate"
            :currency="defaultInput"
            :invalid="$v.formData.hourly_rate.$error"
            class="relative w-full focus:border focus:border-solid focus:border-primary"
            @input="$v.formData.hourly_rate.$touch()"
          />
        </sw-input-group>
        <sw-input-group
          :label="$t('projects.time_logs.description')"
          :error="descriptionError"
          class="mt-3"
          variant="horizontal"
        >
          <sw-textarea
            v-model="formData.description"
            rows="4"
            cols="50"
            @input="$v.formData.description.$touch()"
          />
        </sw-input-group>
      </div>
      <div
        class="z-0 flex justify-end p-4 border-t border-solid border--200 border-modal-bg"
      >
        <sw-button
          class="mr-3 text-sm"
          variant="primary-outline"
          type="button"
          @click="closeTimeLogModal"
        >
          {{ $t('general.cancel') }}
        </sw-button>
        <sw-button :loading="isLoading" variant="primary" type="submit">
          <save-icon class="mr-2" v-if="!isLoading" />
          {{ !isEdit ? $t('general.save') : $t('general.update') }}
        </sw-button>
      </div>
    </form>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Tax from '../../../views/invoices/Tax'
import { ChevronDownIcon, TrashIcon, ViewGridIcon } from '@vue-hero-icons/solid'
import moment from 'moment'

const {
  required,
  minLength,
  between,
  maxLength
} = require('vuelidate/lib/validators')
export default {
  data() {
    return {
      item: {},
      isEdit: false,
      isLoading: false,
      formData: {
        id: null,
        start_date: moment().format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
        start_time: moment().startOf('h').format('HH:mm'),
        end_time: moment().add(1, 'h').startOf('h').format('HH:mm'),
        item_id: null,
        project_id: null,
        hourly_rate: 0,
        description: '',
      },
      defaultInput: {
        decimal: '.',
        thousands: ',',
        prefix: '€ ',
        precision: 2,
        masked: false
      }
    }
  },
  computed: {
    ...mapGetters('modal', [
      'modalDataID',
      'modalData',
      'modalActive',
      'refreshData'
    ]),
    ...mapGetters('item', ['items']),
    ...mapGetters('projects', ['selectedViewProject']),

    descriptionError() {
      if (!this.$v.formData.description.$error) {
        return ''
      }

      if (!this.$v.formData.description.maxLength) {
        return this.$t('validation.description_maxlength')
      }
    },
    nameError() {
      if (!this.$v.formData.name.$error) {
        return ''
      }

      if (!this.$v.formData.name.required) {
        return this.$tc('validation.required')
      } else {
        return this.$tc(
          'validation.name_min_length',
          this.$v.formData.name.$params.minLength.min,
          { count: this.$v.formData.name.$params.minLength.min }
        )
      }
    },
    percentError() {
      if (!this.$v.formData.percent.$error) {
        return ''
      }

      if (!this.$v.formData.percent.required) {
        return this.$t('validation.required')
      } else {
        return this.$t('validation.enter_valid_tax_rate')
      }
    }
  },
  validations: {
    formData: {
      start_date: {
        required
      },
      start_time: {
        required
      },
      end_date: {
        required
      },
      end_time: {
        required
      },
      hourly_rate: {
        required,
      },
      description: {
        maxLength: maxLength(255)
      }
    }
  },
  async created() {
    this.fetchItems();
  },
  async mounted() {
    // this.$refs.name.focus = true
    if (this.modalDataID) {
      this.isEdit = true
      this.setData()
    }
  },
  watch: {
    item(newVal) {
      if (newVal && newVal.price) {
        this.formData.hourly_rate = newVal.price / 100;
      }
    },

    selectedNote() {
      if (this.selectedNote) {
        this.newInvoice.notes = this.selectedNote
      }
    },

    subtotal(newValue) {
      if (this.newInvoice.discount_type === 'percentage') {
        this.newInvoice.discount_val =
          (this.newInvoice.discount * newValue) / 100
      }
    },
  },
  methods: {
    ...mapActions('modal', ['closeModal', 'resetModalData']),
    ...mapActions('timeLogs', ['addTimeLog', 'updateTimeLog']),
    ...mapActions('item', ['fetchItems']),
    ...mapActions('projects', ['fetchViewProject']),

    resetFormData() {
      this.formData = {
        id: null,
        start_date: moment().format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
        start_time: moment().startOf('h').format('HH:mm'),
        end_time: moment().add(1, 'h').startOf('h').format('HH:mm'),
        description: null,
        item_id: null,
        hourly_rate: 0,
        project_id: null
      }
      this.$v.formData.$reset()
    },
    async submitTimeLogData() {
      const data = {
        id: this.formData.id,
        start_date: this.formData.start_date + ' ' + this.formData.start_time,
        end_date: this.formData.start_date + ' ' + this.formData.end_time,
        description: this.formData.description,
        item_id: this.item.id,
        hourly_rate: this.formData.hourly_rate,
        project_id: this.selectedViewProject.project ? this.selectedViewProject.project.id : this.formData.project_id
      };

      this.$v.formData.$touch()
      if (this.$v.$invalid) {
        return true
      }
      this.isLoading = true
      let response
      if (!this.isEdit) {
        response = await this.addTimeLog(data)
      } else {
        response = await this.updateTimeLog(data)
      }
      if (response.data) {
        if (!this.isEdit) {
          window.toastr['success'](
            this.$t('projects.time_logs.created_message')
          )
        } else {
          window.toastr['success'](
            this.$t('projects.time_logs.updated_message')
          )
        }
        window.hub.$emit('newTimeLog', response.data.timeLog)
        this.refreshData ? this.refreshData() : ''
        this.closeTimeLogModal()
        this.isLoading = false
        return true
      }
      window.toastr['error'](response.data.error)
    },
    async setData() {
      await this.fetchItems();
      this.formData = {
        id: this.modalData.id,
        start_date: moment(
          this.modalData.start_date,
          'YYYY-MM-DD'
        ).format('YYYY-MM-DD'),
        end_date: moment(
          this.modalData.end_date,
          'YYYY-MM-DD'
        ).format('YYYY-MM-DD'),
        start_time: moment(
          this.modalData.start_date,
          'YYYY-MM-DD HH:mm'
        ).format('HH:mm'),
        end_time: moment(
          this.modalData.end_date,
          'YYYY-MM-DD HH:mm'
        ).format('HH:mm'),
        description: this.modalData.description,
        item_id: this.modalData.item_id,
        hourly_rate: +this.modalData.hourly_rate,
        project_id: this.modalData.project_id
      };
      this.item = this.items.find(x => x.id === this.modalData.item_id);
    },
    closeTimeLogModal() {
      this.resetModalData()
      this.resetFormData()
      this.closeModal()
    }
  }
}
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.getChartProjects
    ? _c(
        "sw-card",
        { staticClass: "flex flex-col mt-6" },
        [
          _c("div", { staticClass: "grid grid-cols-12" }, [
            _c("div", { staticClass: "col-span-12" }, [
              _c("div", { staticClass: "flex justify-between mt-1 mb-6" }, [
                _c(
                  "h6",
                  { staticClass: "flex items-center sw-section-title" },
                  [
                    _c("chart-square-bar-icon", {
                      staticClass: "h-5 text-primary-400"
                    }),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("customers.time_logs.due")) +
                        "\n        "
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "w-40 h-10 text-right" },
                  [
                    _c(
                      "sw-dropdown",
                      [
                        _c(
                          "sw-button",
                          {
                            attrs: { slot: "activator", variant: "primary" },
                            slot: "activator"
                          },
                          [
                            _c("dots-horizontal-icon", {
                              staticClass: "h-5 -ml-1 -mr-1"
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "sw-dropdown-item",
                          {
                            on: {
                              click: function($event) {
                                return _vm.downloadTimeSheet(false)
                              }
                            }
                          },
                          [
                            _c("arrow-circle-down-icon", {
                              staticClass: "h-5 mr-3 text-gray-600"
                            }),
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("customers.time_logs.download")) +
                                "\n            "
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "sw-dropdown-item",
                          {
                            on: {
                              click: function($event) {
                                return _vm.downloadTimeSheet(true)
                              }
                            }
                          },
                          [
                            _c("arrow-circle-down-icon", {
                              staticClass: "h-5 mr-3 text-gray-600"
                            }),
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t("customers.time_logs.download_price")
                                ) +
                                "\n            "
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "sw-dropdown-item",
                          { on: { click: _vm.generateInvoice } },
                          [
                            _c("arrow-circle-down-icon", {
                              staticClass: "h-5 mr-3 text-gray-600"
                            }),
                            _vm._v(
                              "\n              generate invoice\n            "
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "sw-dropdown-item",
                          { on: { click: _vm.generateInvoiceFromSelected } },
                          [
                            _c("arrow-circle-down-icon", {
                              staticClass: "h-5 mr-3 text-gray-600"
                            }),
                            _vm._v(
                              "\n              generate invoice from selected\n            "
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "sw-dropdown-item",
                          { on: { click: _vm.setSelectedTimeLogsAsPaid } },
                          [
                            _c("arrow-circle-down-icon", {
                              staticClass: "h-5 mr-3 text-gray-600"
                            }),
                            _vm._v(
                              "\n              Set selected as paid\n            "
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "grid grid-cols-12" }, [
            _c(
              "div",
              { staticClass: "col-span-12 xl:col-span-9 xxl:col-span-10" },
              [
                _c("all-projects-chart", {
                  staticClass: "sm:w-full",
                  attrs: {
                    projects: _vm.getChartProjects,
                    labels: _vm.getChartMonths
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "grid col-span-12 mt-6 text-center xl:mt-0 sm:grid-cols-4 xl:text-right xl:col-span-3 xl:grid-cols-1 xxl:col-span-2"
              },
              [
                _c("div", { staticClass: "px-6 py-2" }, [
                  _c("span", { staticClass: "text-xs leading-5 lg:text-sm" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("projects.due_hours")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "block mt-1 text-xl font-semibold leading-8"
                    },
                    [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.getFormattedTotalHoursDue)
                        }
                      })
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "px-6 py-2" }, [
                  _c("span", { staticClass: "text-xs leading-5 lg:text-sm" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("projects.due_income")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "block mt-1 text-xl font-semibold leading-8",
                      staticStyle: { color: "#5851d8" }
                    },
                    [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.getFormattedTotalPriceDue)
                        }
                      })
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "px-6 py-2" }, [
                  _c("span", { staticClass: "text-xs leading-5 lg:text-sm" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("projects.total_hours")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "block mt-1 text-xl font-semibold leading-8"
                    },
                    [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.getFormattedTotalHours)
                        }
                      })
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "px-6 py-2" }, [
                  _c("span", { staticClass: "text-xs leading-5 lg:text-sm" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("projects.total_income")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "block mt-1 text-xl font-semibold leading-8",
                      staticStyle: { color: "#5851d8" }
                    },
                    [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.getFormattedTotalPrice)
                        }
                      })
                    ]
                  )
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("sw-checkbox", {
                staticClass: "hidden md:inline",
                attrs: { variant: "primary", size: "sm" },
                on: { change: _vm.selectAllTimeLogs },
                model: {
                  value: _vm.selectAllFieldStatus,
                  callback: function($$v) {
                    _vm.selectAllFieldStatus = $$v
                  },
                  expression: "selectAllFieldStatus"
                }
              }),
              _vm._v(" "),
              _c("sw-checkbox", {
                staticClass: "md:hidden",
                attrs: {
                  label: _vm.$t("general.select_all"),
                  variant: "primary",
                  size: "sm"
                },
                on: { change: _vm.selectAllTimeLogs },
                model: {
                  value: _vm.selectAllFieldStatus,
                  callback: function($$v) {
                    _vm.selectAllFieldStatus = $$v
                  },
                  expression: "selectAllFieldStatus"
                }
              }),
              _vm._v(" "),
              _c("p", [
                _vm._v("Selected hours: " + _vm._s(_vm.getSelectedHours))
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v("Selected price: " + _vm._s(_vm.getSelectedPrice))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _vm.getTimeLogs.length > 0
            ? _c(
                "sw-table-component",
                {
                  ref: "table",
                  attrs: {
                    "show-filter": false,
                    data: _vm.getTimeLogs,
                    "table-class": "table"
                  }
                },
                [
                  _c("sw-table-column", {
                    attrs: {
                      sortable: false,
                      filterable: false,
                      "cell-class": "no-click"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(row) {
                            return _c(
                              "div",
                              { staticClass: "relative block" },
                              [
                                _c("sw-checkbox", {
                                  attrs: {
                                    id: row.id,
                                    value: row.id,
                                    variant: "primary",
                                    size: "sm"
                                  },
                                  model: {
                                    value: _vm.selectField,
                                    callback: function($$v) {
                                      _vm.selectField = $$v
                                    },
                                    expression: "selectField"
                                  }
                                })
                              ],
                              1
                            )
                          }
                        }
                      ],
                      null,
                      false,
                      1238225993
                    )
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: { sortable: true, filterable: true, label: "#" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(row) {
                            return [
                              _c("span", [_vm._v("#")]),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "font-medium text-primary-500",
                                  on: {
                                    click: function($event) {
                                      return _vm.openTimeLogModal(row)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(row.id) +
                                      "\n        "
                                  )
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2044961180
                    )
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      filterable: true,
                      label: _vm.$t("customers.time_logs.project"),
                      show: "project"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("customers.time_logs.project"))
                                )
                              ]),
                              _vm._v(
                                "\n        " +
                                  _vm._s(row.project.name) +
                                  "\n      "
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      701175545
                    )
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      filterable: true,
                      label: _vm.$t("customers.time_logs.date")
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("customers.time_logs.date"))
                                )
                              ]),
                              _vm._v(
                                "\n        " +
                                  _vm._s(row.formattedStartDate) +
                                  "\n      "
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2011152614
                    )
                  }),
                  _vm._v(" "),
                  _c("sw-table-column", {
                    attrs: {
                      sortable: true,
                      filterable: true,
                      label: _vm.$t("customers.time_logs.hours")
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("customers.time_logs.hours"))
                                )
                              ]),
                              _vm._v(
                                "\n        " + _vm._s(row.hours) + " h\n      "
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1347886392
                    )
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
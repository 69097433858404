<template>
  <base-page class="item-create">
    <sw-page-header class="mb-3" :title="pageTitle">
      <sw-breadcrumb slot="breadcrumbs">
        <sw-breadcrumb-item to="/admin/dashboard" :title="$t('general.home')" />
        <sw-breadcrumb-item to="/admin/projects" :title="$tc('projects.user', 2)" />
        <sw-breadcrumb-item
          v-if="$route.name === 'projects.edit'"
          to="#"
          :title="$t('projects.edit_user')"
          active
        />
        <sw-breadcrumb-item
          v-else
          to="#"
          :title="$t('projects.new_user')"
          active
        />
      </sw-breadcrumb>
      <template slot="actions"></template>
    </sw-page-header>

    <div class="grid grid-cols-12">
      <div class="col-span-12 md:col-span-8">
        <form action="" @submit.prevent="submitProject">
          <sw-card>
            <sw-input-group
              :label="$t('projects.name')"
              :error="nameError"
              class="mb-4"
              required
            >
              <sw-input
                v-model.trim="formData.name"
                :invalid="$v.formData.name.$error"
                class="mt-2"
                focus
                type="text"
                name="name"
                @input="$v.formData.name.$touch()"
              />
            </sw-input-group>

            <sw-input-group
              :label="$t('payments.customer')"
              :error="customerError"
              class="mb-4"
              required
            >

              <sw-select
                v-model="customer"
                :options="customers"
                :searchable="true"
                :show-labels="false"
                :allow-empty="false"
                :placeholder="$t('customers.select_a_customer')"
                label="name"
                class="mt-1"
                track-by="id"
              />
            </sw-input-group>

            <sw-input-group
              :label="$t('items.description')"
              class="mb-4"
            >
              <sw-textarea
                v-model="formData.description"
                rows="2"
                name="description"
              />
            </sw-input-group>


            <div class="mt-6 mb-4">
              <sw-button
                :loading="isLoading"
                variant="primary"
                type="submit"
                size="lg"
                class="flex justify-center w-full md:w-auto"
              >
                <save-icon v-if="!isLoading" class="mr-2 -ml-1" />
                {{ isEdit ? $t('projects.update_user') : $t('projects.save_user') }}
              </sw-button>
            </div>
          </sw-card>
        </form>
      </div>
    </div>
  </base-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

const {
  required,
  minLength,
  email,
  numeric,
  minValue,
  maxLength,
  requiredIf
} = require('vuelidate/lib/validators')

export default {
  data() {
    return {
      isLoading: false,
      title: 'Add User',
      customer: null,
      formData: {
        name: '',
        description: null
      }
    }
  },
  computed: {
    ...mapGetters('user', ['currentUser']),
    ...mapGetters('customer', ['customers']),
    isSuperAdmin() {
      return this.currentUser.role == 'super admin'
    },

    customerError() {
      if (!this.$v.customer) {
        return ''
      }
      if (!this.$v.customer.$error) {
        return ''
      }

      if (!this.$v.customer.required) {
        return this.$tc('validation.required')
      }
    },

    pageTitle() {
      if (this.$route.name === 'projects.edit') {
        return this.$t('projects.edit_user')
      }
      return this.$t('projects.new_user')
    },

    isEdit() {
      if (this.$route.name === 'projects.edit') {
        return true
      }
      return false
    },

    nameError() {
      if (!this.$v.formData.name.$error) {
        return ''
      }
      if (!this.$v.formData.name.required) {
        return this.$t('validation.required')
      }
      if (!this.$v.formData.name.minLength) {
        return this.$tc(
          'validation.name_min_length',
          this.$v.formData.name.$params.minLength.min,
          { count: this.$v.formData.name.$params.minLength.min }
        )
      }
    },
  },

  async created() {
    if (!this.isSuperAdmin) {
      this.$router.push('/admin/dashboard')
    }

    await this.fetchCustomers({ limit: 'all' })

    if (this.isEdit) {
      await this.loadEditData()
    }
  },

  mounted() {
    this.$v.formData.$reset()
    console.log(this.customers)
  },
  validations: {
    formData: {
      name: {
        required,
        minLength: minLength(3)
      }
    }
  },

  methods: {
    ...mapActions('projects', ['addProject', 'fetchProject', 'updateProject']),
    ...mapActions('customer', ['fetchCustomers']),

    async loadEditData() {
      let response = await this.fetchProject(this.$route.params.id)
      this.customer = this.customers.find(x => x.id === response.data.project.user_id);

      if (response.data) {
        this.formData = { ...this.formData, ...response.data.project }
      }


    },

    async submitProject() {
      this.$v.formData.$touch()

      if (this.$v.$invalid) {
        return true
      }

      try {
        let response
        this.isLoading = true
        if (this.isEdit) {
          response = await this.updateProject(this.formData)
          if (response.data.success) {
            window.toastr['success'](this.$tc('projects.updated_message'))
            this.$router.push('/admin/projects')
            this.isLoading = false
          }
          if (response.data.error) {
            window.toastr['error'](this.$t('validation.email_already_taken'))
          }
        } else {
          response = await this.addProject(this.formData)
          if (response.data.success) {
            this.isLoading = false
            if (!this.isEdit) {
              window.toastr['success'](this.$tc('projects.created_message'))
              this.$router.push('/admin/projects')
              return true
            }
          }
        }
      } catch (err) {
        if (err.response.data.errors.email) {
          this.isLoading = false
        }
      }
    }
  },
  watch: {

    customer(newValue) {
      this.formData.user_id = newValue.id
    }
  }
}
</script>

<template>
  <sw-card v-if="chartData" class="flex flex-col mt-6">
    <div class="grid grid-cols-12">
      <div class="col-span-12 xl:col-span-9 xxl:col-span-10">
        <div class="flex justify-between mt-1 mb-6">
          <h6 class="flex items-center sw-section-title">
            <chart-square-bar-icon class="h-5 text-primary-400" />{{
              $t('projects.worked_time')
            }}
          </h6>
          <div class="w-40 h-10">
            <sw-select
              v-model="selectedYear"
              :options="years"
              :allow-empty="false"
              :show-labels="false"
              :placeholder="$t('dashboard.select_year')"
              @select="onChangeYear"
            />
          </div>
        </div>
        <hour-chart
          v-if="isLoaded"
          :format-money="$utils.formatMoney"
          :format-graph-money="$utils.formatGraphMoney"
          :hours="getChartHours"
          :price="getChartPrice"
          :labels="getChartMonths"
          class="sm:w-full"
        />
      </div>
      <div
        class="grid col-span-12 mt-6 text-center xl:mt-0 sm:grid-cols-4 xl:text-right xl:col-span-3 xl:grid-cols-1 xxl:col-span-2"
      >
        <div class="px-6 py-2">
          <span class="text-xs leading-5 lg:text-sm">
            {{ $t('projects.due_hours') }}
          </span>
          <br />
          <span class="block mt-1 text-xl font-semibold leading-8">
            <div v-html="getFormattedTotalHoursDue" />
          </span>
        </div>
        <div class="px-6 py-2">
          <span class="text-xs leading-5 lg:text-sm">
            {{ $t('projects.due_income') }}
          </span>
          <br />
          <span
            class="block mt-1 text-xl font-semibold leading-8"
            style="color: #5851d8"
          >
            <div v-html="getFormattedTotalPriceDue" />
          </span>
        </div>
        <div class="px-6 py-2">
          <span class="text-xs leading-5 lg:text-sm">
            {{ $t('projects.total_hours') }}
          </span>
          <br />
          <span
            class="block mt-1 text-xl font-semibold leading-8"
          >
            <div v-html="getFormattedTotalHours" />
          </span>
        </div>
        <div class="px-6 py-2">
          <span class="text-xs leading-5 lg:text-sm">
            {{ $t('projects.total_income') }}

          </span>
          <br />
          <span
            class="block mt-1 text-xl font-semibold leading-8"
            style="color: #5851d8"
          >
            <div v-html="getFormattedTotalPrice" />
          </span>
        </div>
      </div>
    </div>

    <!-- basic info -->
    <project-info />
  </sw-card>
</template>

<script>
import ProjectInfo from './ProjectInfo'
import HourChart from '../../../components/chartjs/HourChart'
import { mapActions, mapGetters } from 'vuex'
import { ChartSquareBarIcon } from '@vue-hero-icons/outline'

export default {
  components: {
    HourChart,
    ProjectInfo,
    ChartSquareBarIcon,
  },
  data() {
    return {
      id: null,
      customers: [],
      isLoaded: false,
      chartData: null,
      years: ['This year', 'Previous year'],
      selectedYear: 'This year',
    }
  },
  computed: {
    ...mapGetters('company', ['defaultCurrency']),
    ...mapGetters('projects', ['selectedViewProject']),

    getChartHoursDue() {
      if (this.chartData && this.chartData.hoursDueTotals) {
        return this.chartData.hoursDueTotals
      }
      return []
    },
    getChartHours() {
      if (this.chartData && this.chartData.hoursTotals) {
        return this.chartData.hoursTotals
      }
      return []
    },
    getChartPriceDue() {
      if (this.chartData && this.chartData.priceDueTotals) {
        return this.chartData.priceDueTotals
      }
      return []
    },
    getChartPrice() {
      if (this.chartData && this.chartData.priceTotals) {
        return this.chartData.priceTotals
      }
      return []
    },
    getChartMonths() {
      if (this.chartData && this.chartData.months) {
        return this.chartData.months
      }
      return []
    },
    getFormattedTotalHoursDue() {
      if (this.chartData && this.chartData.hoursDueTotal) {
        return this.chartData.hoursDueTotal;
      }
      return 0
    },
    getFormattedTotalHours() {
      if (this.chartData && this.chartData.hoursTotal) {
        return this.chartData.hoursTotal;
      }
      return 0
    },
    getFormattedTotalPriceDue() {
      if (this.chartData && this.chartData.priceDueTotal) {
        return this.$utils.formatMoney(
          this.chartData.priceDueTotal,
          this.defaultCurrency
        )
      }
      return 0
    },
    getFormattedTotalPrice() {
      if (this.chartData && this.chartData.priceTotal) {
        return this.$utils.formatMoney(
          this.chartData.priceTotal,
          this.defaultCurrency
        )
      }
      return 0
    }
  },
  watch: {
    $route(to, from) {
      this.loadCustomer()
      this.selectedYear = 'This year'
    },
    async selectedViewProject(to, from){
      this.isLoaded = false;
      this.chartData = to.chartData;
      this.isLoaded = true;
      this.$forceUpdate();
      console.log(to)
      // if(to !== from){
      //   console.log('selectedViewProjectChanged')
      //   await this.loadProject();
      // }

    },
    isLoaded(to, from){
      console.log('is loaded changed', to, from)
    }
  },
  created() {
    // this.loadProject()
  },
  methods: {
    ...mapActions('projects', ['fetchViewProject']),

    // async loadProject() {
    //   this.isLoaded = false
    //   let response = await this.fetchViewProject({ id: this.$route.params.id })
    //   if (response.data) {
    //     this.chartData = response.data.chartData
    //   }
    //   this.isLoaded = true
    // },
    async onChangeYear(data) {
      if (data == 'Previous year') {
        let response = await this.fetchViewProject({
          id: this.$route.params.id,
          previous_year: true,
        })
        if (response.data) {
          this.chartData = response.data.chartData
        }
        return true
      }
      let response = await this.fetchViewProject({ id: this.$route.params.id })
      if (response.data) {
        this.chartData = response.data.chartData
      }
      return true
    },
  },
}
</script>

import * as types from './mutation-types'
import * as searchTypes from '../search/mutation-types'

export const fetchProjects = ({ commit, dispatch, state }, params) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/v1/projects`, { params })
      .then((response) => {
        commit(types.BOOTSTRAP_PROJECTS, response.data.projects.data)
        commit(types.SET_TOTAL_PROJECTS, response.data.projects.total)
        // commit(
        //   'search/' + searchTypes.SET_PROJECT_LISTS,
        //   response.data.projects.data,
        //   { root: true }
        // )
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}


export const fetchViewProject = ({ commit, dispatch }, params) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/v1/projects/${params.id}/stats`, { params })
      .then((response) => {
        commit(types.SET_SELECTED_VIEW_PROJECT, response.data)
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const fetchProject = ({ commit, dispatch }, id) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/v1/projects/${id}`)
      .then((response) => {
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const addProject = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post('/api/v1/projects', data)
      .then((response) => {
        commit(types.ADD_PROJECT, response.data)
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const updateProject = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .put(`/api/v1/projects/${data.id}`, data)
      .then((response) => {
        if (response.data.success) {
          commit(types.UPDATE_PROJECT, response.data)
        }
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const deleteProject = ({ commit, dispatch, state }, project) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post(`/api/v1/projects/delete`, {
        projects: project,
      })
      .then((response) => {
        commit(types.DELETE_PROJECT, project)
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const deleteMultipleProjects = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post(`/api/v1/projects/delete`, { projects: state.selectedProjects })
      .then((response) => {
        commit(types.DELETE_MULTIPLE_PROJECTS, state.selectedProjects)
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const setSelectAllState = ({ commit, dispatch, state }, data) => {
  commit(types.SET_SELECT_ALL_STATE, data)
}

export const selectAllProjects = ({ commit, dispatch, state }) => {
  if (state.selectedProjects.length === state.projects.length) {
    commit(types.SET_SELECTED_PROJECTS, [])
    commit(types.SET_SELECT_ALL_STATE, false)
  } else {
    let allProjectIds = state.projects.map((project) => project.id)
    commit(types.SET_SELECTED_PROJECTS, allProjectIds)
    commit(types.SET_SELECT_ALL_STATE, true)
  }
}

export const selectedProject = ({ commit, dispatch, state }, data) => {
  commit(types.SET_SELECTED_PROJECTS, data)
  if (state.selectProjects.length === state.projects.length) {
    commit(types.SET_SELECT_ALL_STATE, true)
  } else {
    commit(types.SET_SELECT_ALL_STATE, false)
  }
}

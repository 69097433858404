var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tax-type-modal" }, [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.submitTimeLogData($event)
          }
        }
      },
      [
        _c(
          "div",
          { staticClass: "p-8 sm:p-6" },
          [
            _c(
              "sw-input-group",
              {
                staticClass: "mt-3",
                attrs: {
                  label: _vm.$t("projects.time_logs.start_date"),
                  variant: "horizontal",
                  required: ""
                }
              },
              [
                _c("base-date-picker", {
                  attrs: {
                    "calendar-button": true,
                    "calendar-button-icon": "calendar"
                  },
                  model: {
                    value: _vm.formData.start_date,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "start_date", $$v)
                    },
                    expression: "formData.start_date"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "mt-3",
                attrs: {
                  label: _vm.$t("projects.time_logs.start_time"),
                  variant: "horizontal",
                  required: ""
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "grid gap-6 sm:grid-col-1 md:grid-cols-2" },
                  [
                    _c("base-time-picker", {
                      model: {
                        value: _vm.formData.start_time,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "start_time", $$v)
                        },
                        expression: "formData.start_time"
                      }
                    }),
                    _vm._v(" "),
                    _c("base-time-picker", {
                      model: {
                        value: _vm.formData.end_time,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "end_time", $$v)
                        },
                        expression: "formData.end_time"
                      }
                    })
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "mt-3",
                attrs: {
                  label: _vm.$t("projects.time_logs.item"),
                  variant: "horizontal",
                  required: ""
                }
              },
              [
                _c("sw-select", {
                  staticClass: "mt-1",
                  attrs: {
                    options: _vm.items,
                    searchable: true,
                    "show-labels": false,
                    "allow-empty": false,
                    placeholder: _vm.$t("customers.select_a_customer"),
                    label: "name",
                    "track-by": "id"
                  },
                  model: {
                    value: _vm.item,
                    callback: function($$v) {
                      _vm.item = $$v
                    },
                    expression: "item"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "mt-3",
                attrs: {
                  label: _vm.$t("projects.time_logs.hourly_rate"),
                  variant: "horizontal",
                  required: ""
                }
              },
              [
                _c("sw-money", {
                  staticClass:
                    "relative w-full focus:border focus:border-solid focus:border-primary",
                  attrs: {
                    currency: _vm.defaultInput,
                    invalid: _vm.$v.formData.hourly_rate.$error
                  },
                  on: {
                    input: function($event) {
                      return _vm.$v.formData.hourly_rate.$touch()
                    }
                  },
                  model: {
                    value: _vm.formData.hourly_rate,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "hourly_rate", $$v)
                    },
                    expression: "formData.hourly_rate"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "mt-3",
                attrs: {
                  label: _vm.$t("projects.time_logs.description"),
                  error: _vm.descriptionError,
                  variant: "horizontal"
                }
              },
              [
                _c("sw-textarea", {
                  attrs: { rows: "4", cols: "50" },
                  on: {
                    input: function($event) {
                      return _vm.$v.formData.description.$touch()
                    }
                  },
                  model: {
                    value: _vm.formData.description,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "description", $$v)
                    },
                    expression: "formData.description"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "z-0 flex justify-end p-4 border-t border-solid border--200 border-modal-bg"
          },
          [
            _c(
              "sw-button",
              {
                staticClass: "mr-3 text-sm",
                attrs: { variant: "primary-outline", type: "button" },
                on: { click: _vm.closeTimeLogModal }
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("general.cancel")) +
                    "\n        "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "sw-button",
              {
                attrs: {
                  loading: _vm.isLoading,
                  variant: "primary",
                  type: "submit"
                }
              },
              [
                !_vm.isLoading
                  ? _c("save-icon", { staticClass: "mr-2" })
                  : _vm._e(),
                _vm._v(
                  "\n          " +
                    _vm._s(
                      !_vm.isEdit
                        ? _vm.$t("general.save")
                        : _vm.$t("general.update")
                    ) +
                    "\n        "
                )
              ],
              1
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-page",
    { staticClass: "xl:pl-96" },
    [
      _c(
        "sw-page-header",
        { attrs: { title: _vm.pageTitle } },
        [
          _c(
            "template",
            { slot: "actions" },
            [
              _c(
                "sw-button",
                {
                  staticClass: "mr-3",
                  attrs: {
                    "tag-name": "router-link",
                    to: "/admin/customers/" + _vm.$route.params.id + "/edit",
                    variant: "primary-outline"
                  }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("general.edit")) + "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "sw-dropdown",
                { attrs: { position: "bottom-end" } },
                [
                  _c(
                    "sw-button",
                    {
                      staticClass: "mr-3",
                      attrs: { slot: "activator", variant: "primary" },
                      slot: "activator"
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("customers.new_transaction")) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-dropdown-item",
                    {
                      attrs: {
                        "tag-name": "router-link",
                        to:
                          "/admin/estimates/create?customer=" +
                          _vm.$route.params.id
                      }
                    },
                    [
                      _c("document-icon", {
                        staticClass: "h-5 mr-3 text-gray-600"
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("estimates.new_estimate")) +
                          "\n        "
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-dropdown-item",
                    {
                      attrs: {
                        "tag-name": "router-link",
                        to:
                          "/admin/invoices/create?customer=" +
                          _vm.$route.params.id
                      }
                    },
                    [
                      _c("document-text-icon", {
                        staticClass: "h-5 mr-3 text-gray-600"
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("invoices.new_invoice")) +
                          "\n        "
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-dropdown-item",
                    {
                      attrs: {
                        "tag-name": "router-link",
                        to:
                          "/admin/payments/create?customer=" +
                          _vm.$route.params.id
                      }
                    },
                    [
                      _c("credit-card-icon", {
                        staticClass: "h-5 mr-3 text-gray-600"
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("payments.new_payment")) +
                          "\n        "
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-dropdown-item",
                    {
                      attrs: {
                        "tag-name": "router-link",
                        to:
                          "/admin/expenses/create?customer=" +
                          _vm.$route.params.id
                      }
                    },
                    [
                      _c("calculator-icon", {
                        staticClass: "h-5 mr-3 text-gray-600"
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("expenses.new_expense")) +
                          "\n        "
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-dropdown",
                [
                  _c(
                    "sw-button",
                    {
                      attrs: { slot: "activator", variant: "primary" },
                      slot: "activator"
                    },
                    [
                      _c("dots-horizontal-icon", {
                        staticClass: "h-5 -ml-1 -mr-1"
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-dropdown-item",
                    {
                      on: {
                        click: function($event) {
                          return _vm.removeCustomer(_vm.$route.params.id)
                        }
                      }
                    },
                    [
                      _c("trash-icon", {
                        staticClass: "h-5 mr-3 text-gray-600"
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("general.delete")) +
                          "\n        "
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        2
      ),
      _vm._v(" "),
      _c("customer-view-sidebar"),
      _vm._v(" "),
      _c("customer-chart"),
      _vm._v(" "),
      _c("customer-due-time-logs")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <sw-card class="flex flex-col mt-6">
    <div class="grid grid-cols-12">
      <div class="col-span-12">
        <div class="flex justify-between mt-1 mb-6">
          <h6 class="flex items-center sw-section-title">
            <chart-square-bar-icon class="h-5 text-primary-400" />
            {{
              $t('customers.time_logs.due')
            }}
          </h6>
          <div class="w-40 h-10 text-right">
            <sw-dropdown>
              <sw-button slot="activator" variant="primary">
                <dots-horizontal-icon class="h-5 -ml-1 -mr-1" />
              </sw-button>

              <sw-dropdown-item @click="downloadTimeSheet(false)">
                <arrow-circle-down-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('customers.time_logs.download') }}
              </sw-dropdown-item>
              <sw-dropdown-item @click="downloadTimeSheet(true)">
                <arrow-circle-down-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('customers.time_logs.download_price') }}
              </sw-dropdown-item>
            </sw-dropdown>
          </div>
        </div>

      </div>
    </div>
    <sw-table-component
      v-if="getTimeLogs.length > 0"
      ref="table"
      :show-filter="false"
      :data="getTimeLogs"
      table-class="table"
    >
      <sw-table-column
        :sortable="true"
        :filterable="true"
        :label="'#'"
      >
        <template slot-scope="row">
          <span>#</span>
          <a
            @click="openTimeLogModal(row)"
            class="font-medium text-primary-500"
          >
            {{ row.id }}
          </a>
        </template>
      </sw-table-column>
      <sw-table-column
        :sortable="true"
        :filterable="true"
        :label="$t('customers.time_logs.project')"
        show="project"
      >
        <template slot-scope="row">
          <span>{{ $t('customers.time_logs.project') }}</span>
          {{ row.project.name }}
        </template>
      </sw-table-column>
      <sw-table-column
        :sortable="true"
        :filterable="true"
        :label="$t('customers.time_logs.date')"
      >
        <template slot-scope="row">
          <span>{{ $t('customers.time_logs.date') }}</span>
          {{ row.formattedStartDate }}
        </template>
      </sw-table-column>
      <sw-table-column
        :sortable="true"
        :filterable="true"
        :label="$t('customers.time_logs.hours')"
      >
        <template slot-scope="row">
          <span>{{ $t('customers.time_logs.hours') }}</span>
          {{ row.hours }} h
        </template>
      </sw-table-column>

    </sw-table-component>

  </sw-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ChartSquareBarIcon, DotsHorizontalIcon, ArrowCircleDownIcon } from '@vue-hero-icons/outline'

export default {
  components: {
    ChartSquareBarIcon,
    DotsHorizontalIcon,
    ArrowCircleDownIcon
  },
  data() {
    return {
      timeLogs: null,
      customer: null,
      customFields: []
    }
  },
  computed: {

    getTimeLogs() {
      if (this.selectedViewProject.project && this.selectedViewProject.project.due_time_logs) {
        return this.selectedViewProject.project.due_time_logs
      }

      return []
    },
    ...mapGetters('projects', ['selectedViewProject'])
  },
  watch: {
    $route(to, from) {
      this.project = this.selectedViewProject
    }
  },
  methods: {
    ...mapActions('modal', ['openModal']),
    ...mapActions('projects', ['fetchViewProject']),
    downloadTimeSheet(withPrice) {
      window.location.href = `/api/v1/projects/${this.selectedViewProject.project.id}/time-sheet?withPrice=${withPrice ? 1 : 0}`
    },

    openTimeLogModal(timeLog) {
      this.openModal({
        title: this.$t('projects.time_logs.new_time_log'),
        componentName: 'TimeLogModal',
        id: timeLog.id,
        data: timeLog,
        refreshData: this.refreshData
      })
    },
    refreshData() {
      this.fetchViewProject({ id: this.$route.params.id })
    }
  }

}
</script>

import * as types from './mutation-types'

export default {
  [types.BOOTSTRAP_PROJECTS](state, projects) {
    state.projects = projects
  },

  [types.SET_TOTAL_PROJECTS](state, totalProjects) {
    state.totalProjects = totalProjects
  },

  [types.ADD_PROJECT](state, data) {
    state.projects.push(data.project)
  },

  [types.UPDATE_PROJECT](state, data) {
    let pos = state.projects.findIndex((project) => project.id === data.project.id)

    state.projects[pos] = data.project
  },

  [types.DELETE_PROJECT](state, id) {
    let index = state.projects.findIndex((project) => project.id === id[0])
    state.projects.splice(index, 1)
  },

  [types.DELETE_MULTIPLE_PROJECTS](state, selectedProjects) {
    selectedProjects.forEach((project) => {
      let index = state.projects.findIndex((_project) => _project.id === project.id)
      state.projects.splice(index, 1)
    })

    state.selectedProjects = []
  },

  [types.SET_SELECTED_PROJECTS](state, data) {
    state.selectedProjects = data
  },

  [types.SET_SELECT_ALL_STATE](state, data) {
    state.selectAllField = data
  },

  [types.SET_SELECTED_VIEW_PROJECT](state, selectedViewProject) {
    state.selectedViewProject = selectedViewProject
  },
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.chartData
    ? _c(
        "sw-card",
        { staticClass: "flex flex-col mt-6" },
        [
          _c("div", { staticClass: "grid grid-cols-12" }, [
            _c(
              "div",
              { staticClass: "col-span-12 xl:col-span-9 xxl:col-span-10" },
              [
                _c("div", { staticClass: "flex justify-between mt-1 mb-6" }, [
                  _c(
                    "h6",
                    { staticClass: "flex items-center sw-section-title" },
                    [
                      _c("chart-square-bar-icon", {
                        staticClass: "h-5 text-primary-400"
                      }),
                      _vm._v(
                        _vm._s(_vm.$t("projects.worked_time")) + "\n        "
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "w-40 h-10" },
                    [
                      _c("sw-select", {
                        attrs: {
                          options: _vm.years,
                          "allow-empty": false,
                          "show-labels": false,
                          placeholder: _vm.$t("dashboard.select_year")
                        },
                        on: { select: _vm.onChangeYear },
                        model: {
                          value: _vm.selectedYear,
                          callback: function($$v) {
                            _vm.selectedYear = $$v
                          },
                          expression: "selectedYear"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _vm.isLoaded
                  ? _c("hour-chart", {
                      staticClass: "sm:w-full",
                      attrs: {
                        "format-money": _vm.$utils.formatMoney,
                        "format-graph-money": _vm.$utils.formatGraphMoney,
                        hours: _vm.getChartHours,
                        price: _vm.getChartPrice,
                        labels: _vm.getChartMonths
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "grid col-span-12 mt-6 text-center xl:mt-0 sm:grid-cols-4 xl:text-right xl:col-span-3 xl:grid-cols-1 xxl:col-span-2"
              },
              [
                _c("div", { staticClass: "px-6 py-2" }, [
                  _c("span", { staticClass: "text-xs leading-5 lg:text-sm" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("projects.due_hours")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "block mt-1 text-xl font-semibold leading-8"
                    },
                    [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.getFormattedTotalHoursDue)
                        }
                      })
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "px-6 py-2" }, [
                  _c("span", { staticClass: "text-xs leading-5 lg:text-sm" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("projects.due_income")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "block mt-1 text-xl font-semibold leading-8",
                      staticStyle: { color: "#5851d8" }
                    },
                    [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.getFormattedTotalPriceDue)
                        }
                      })
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "px-6 py-2" }, [
                  _c("span", { staticClass: "text-xs leading-5 lg:text-sm" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("projects.total_hours")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "block mt-1 text-xl font-semibold leading-8"
                    },
                    [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.getFormattedTotalHours)
                        }
                      })
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "px-6 py-2" }, [
                  _c("span", { staticClass: "text-xs leading-5 lg:text-sm" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("projects.total_income")) +
                        "\n\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "block mt-1 text-xl font-semibold leading-8",
                      staticStyle: { color: "#5851d8" }
                    },
                    [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.getFormattedTotalPrice)
                        }
                      })
                    ]
                  )
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c("project-info")
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
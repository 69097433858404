<template>
  <div
    class="pt-6 mt-5 border-t-2 border-solid lg:pt-8 md:pt-4"
    style="border-top-color: #f9fbff"
  >
    <div class="col-span-12">
      <p class="text-gray-500 uppercase sw-section-title">
        {{ $t('customers.basic_info') }}
      </p>
      <div
        class="grid grid-cols-1 gap-4 mt-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1"
      >
        <div>
          <p
            class="mb-1 text-sm font-normal leading-5 non-italic text-primary-800"
          >
            {{ $t('projects.name') }}
          </p>
          <p class="text-sm font-bold leading-5 text-black non-italic">
            {{
              selectedViewProject.project &&
              selectedViewProject.project.name
                ? selectedViewProject.project.name
                : ''
            }}
          </p>
        </div>
        <div>
          <p
            class="mb-1 text-sm font-normal leading-5 non-italic text-primary-800"
          >
            {{ $t('projects.added_on') }}
          </p>
          <p class="text-sm font-bold leading-5 text-black non-italic">
            {{
              selectedViewProject.project &&
              selectedViewProject.project.formattedCreatedAt
                ? selectedViewProject.project.formattedCreatedAt
                : ''
            }}
          </p>
        </div>
        <div>
          <p
            class="mb-1 text-sm font-normal leading-5 non-italic text-primary-800"
          >
            {{ $t('projects.customer') }}
          </p>
          <p class="text-sm font-bold leading-5 text-black non-italic">
            {{
              selectedViewProject.project &&
              selectedViewProject.project.customer &&
              selectedViewProject.project.customer.name
                ? selectedViewProject.project.customer.name
                : ''
            }}
          </p>
        </div>
      </div>
      <div
        class="grid grid-cols-1 gap-4 mt-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1"
      >
      </div>

      <div
        class="grid grid-cols-1 gap-4 md:grid-cols-2 sm:grid-cols-1 lg:grid-cols-2"
      >
      </div>

      <!-- Custom Fields -->
      <p
        v-if="getCustomField.length > 0"
        class="mt-8 text-gray-500 uppercase sw-section-title"
      >
        {{ $t('settings.custom_fields.title') }}
      </p>

      <div
        class="grid grid-cols-1 gap-4 mt-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1"
      >
        <div
          v-for="(field, index) in getCustomField"
          :key="index"
          :required="field.is_required ? true : false"
        >
          <p
            class="mb-1 text-sm font-normal leading-5 non-italic text-primary-800"
          >
            {{ field.custom_field.label }}
          </p>
          <p
            v-if="field.type === 'Switch'"
            class="text-sm font-bold leading-5 text-black non-italic"
          >
            <span v-if="field.defaultAnswer === 1"> Yes </span>
            <span v-else> No </span>
          </p>
          <p v-else class="text-sm font-bold leading-5 text-black non-italic">
            {{ field.defaultAnswer }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      project: null,
      customFields: []
    }
  },
  computed: {

    getCustomField() {
      if (this.selectedViewProject.project.fields) {
        return this.selectedViewProject.project.fields
      }
      return []
    },

    ...mapGetters('projects', ['selectedViewProject'])
  },
  watch: {
    $route(to, from) {
      this.project = this.selectedViewProject
    }
  }
}
</script>

import * as types from './mutation-types'
import * as searchTypes from '../search/mutation-types'

export const fetchTimeLogs = ({ commit, dispatch, state }, params) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/v1/time-logs`, { params })
      .then((response) => {
        commit(types.BOOTSTRAP_TIME_LOGS, response.data.timeLogs.data)
        commit(types.SET_TOTAL_TIME_LOGS, response.data.timeLogs.total)
        // commit(
        //   'search/' + searchTypes.SET_TIME_LOG_LISTS,
        //   response.data.timeLogs.data,
        //   { root: true }
        // )
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}


export const fetchViewTimeLog = ({ commit, dispatch }, params) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/v1/time-logs/${params.id}`, { params })
      .then((response) => {
        commit(types.SET_SELECTED_VIEW_TIME_LOG, response.data)
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const fetchTimeLog = ({ commit, dispatch }, id) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/v1/time-logs/${id}`)
      .then((response) => {
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const addTimeLog = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post('/api/v1/time-logs', data)
      .then((response) => {
        commit(types.ADD_TIME_LOG, response.data)
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const updateTimeLog = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .put(`/api/v1/time-logs/${data.id}`, data)
      .then((response) => {
        if (response.data.success) {
          commit(types.UPDATE_TIME_LOG, response.data)
        }
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const deleteTimeLog = ({ commit, dispatch, state }, project) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post(`/api/v1/time-logs/delete`, {
        timeLogs: project,
      })
      .then((response) => {
        commit(types.DELETE_TIME_LOG, project)
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const deleteMultipleTimeLogs = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post(`/api/v1/time-logs/delete`, { timeLogs: state.selectedTimeLogs })
      .then((response) => {
        commit(types.DELETE_MULTIPLE_TIME_LOGS, state.selectedTimeLogs)
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const setSelectAllState = ({ commit, dispatch, state }, data) => {
  commit(types.SET_SELECT_ALL_STATE, data)
}

export const selectAllTimeLogs = ({ commit, dispatch, state }) => {
  if (state.selectedTimeLogs.length === state.timeLogs.length) {
    commit(types.SET_SELECTED_TIME_LOGS, [])
    commit(types.SET_SELECT_ALL_STATE, false)
  } else {
    let allTimeLogIds = state.timeLogs.map((project) => project.id)
    commit(types.SET_SELECTED_TIME_LOGS, allTimeLogIds)
    commit(types.SET_SELECT_ALL_STATE, true)
  }
}

export const selectedTimeLog = ({ commit, dispatch, state }, data) => {
  commit(types.SET_SELECTED_TIME_LOGS, data)
  if (state.selectTimeLogs.length === state.timeLogs.length) {
    commit(types.SET_SELECT_ALL_STATE, true)
  } else {
    commit(types.SET_SELECT_ALL_STATE, false)
  }
}

<template>
  <base-page class="xl:pl-96">
    <sw-page-header :title="pageTitle">
      <template slot="actions">
        <sw-button
          tag-name="router-link"
          :to="`/admin/projects/${$route.params.id}/edit`"
          class="mr-3"
          variant="primary-outline"
        >
          {{ $t('general.edit') }}
        </sw-button>
        <sw-dropdown position="bottom-end">
          <sw-button slot="activator" class="mr-3" variant="primary">
            {{ $t('projects.new_transaction') }}
          </sw-button>
          <sw-dropdown-item @click="openTimeLogModal"
          >
            <clock-icon class="h-5 mr-3 text-gray-600" />
            {{ $t('projects.time_logs.new_time_log') }}
          </sw-dropdown-item>
        </sw-dropdown>
        <sw-dropdown>
          <sw-button slot="activator" variant="primary">
            <dots-horizontal-icon class="h-5 -ml-1 -mr-1" />
          </sw-button>

          <sw-dropdown-item @click="removeProject($route.params.id)">
            <trash-icon class="h-5 mr-3 text-gray-600" />
            {{ $t('general.delete') }}
          </sw-dropdown-item>
        </sw-dropdown>
      </template>
    </sw-page-header>

    <!-- sidebar -->
    <project-view-sidebar />

    <!-- Chart -->
    <project-chart />

    <!--    Time Logs-->
    <project-due-time-logs />
  </base-page>
</template>

<script>
import {
  DotsHorizontalIcon,
  TrashIcon,
  DocumentIcon,
  DocumentTextIcon,
  CreditCardIcon,
  CalculatorIcon,
  ClockIcon
} from '@vue-hero-icons/solid'
import ProjectViewSidebar from './partials/ProjectViewSidebar'
import ProjectChart from './partials/ProjectChart'
import ProjectDueTimeLogs from './partials/ProjectDueTimeLogs'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    ProjectDueTimeLogs,
    DotsHorizontalIcon,
    ProjectViewSidebar,
    DocumentIcon,
    DocumentTextIcon,
    CreditCardIcon,
    CalculatorIcon,
    ProjectChart,
    TrashIcon,
    ClockIcon
  },
  data() {
    return {
      project: null
    }
  },
  computed: {
    ...mapGetters('projects', ['selectedViewProject']),
    pageTitle() {
      return this.selectedViewProject.project
        ? this.selectedViewProject.project.name
        : ''
    }
  },
  created() {
    this.fetchViewProject({ id: this.$route.params.id })
  },
  methods: {
    ...mapActions('modal', ['openModal']),

    ...mapActions('projects', [
      'fetchViewProject',
      'selectProject',
      'deleteMultipleProjects'
    ]),
    refreshData(){
      console.log('refreshing data from view');
      this.fetchViewProject({ id: this.$route.params.id })

    },

    openTimeLogModal() {
      this.openModal({
        title: this.$t('projects.time_logs.new_time_log'),
        componentName: 'TimeLogModal',
        refreshData: this.refreshData,
      })
    },

    async removeProject(id) {
      swal({
        title: this.$t('general.are_you_sure'),
        text: this.$tc('projects.confirm_delete'),
        icon: '/assets/icon/trash-solid.svg',
        buttons: true,
        dangerMode: true
      }).then(async (willDelete) => {
        if (willDelete) {
          let data = [id]
          this.selectProject(data)
          let res = await this.deleteMultipleProjects()
          if (res.data.success) {
            window.toastr['success'](this.$tc('projects.deleted_message'))
            this.$router.push('/admin/projects')
            return true
          } else if (request.data.error) {
            window.toastr['error'](res.data.message)
          }
        }
      })
    }
  }
}
</script>

<template>
  <sw-card v-if="getChartProjects" class="flex flex-col mt-6">
    <div class="grid grid-cols-12">
      <div class="col-span-12">
        <div class="flex justify-between mt-1 mb-6">
          <h6 class="flex items-center sw-section-title">
            <chart-square-bar-icon class="h-5 text-primary-400" />
            {{
              $t('customers.time_logs.due')
            }}
          </h6>
          <div class="w-40 h-10 text-right">
            <sw-dropdown>
              <sw-button slot="activator" variant="primary">
                <dots-horizontal-icon class="h-5 -ml-1 -mr-1" />
              </sw-button>

              <sw-dropdown-item @click="downloadTimeSheet(false)">
                <arrow-circle-down-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('customers.time_logs.download') }}
              </sw-dropdown-item>
              <sw-dropdown-item @click="downloadTimeSheet(true)">
                <arrow-circle-down-icon class="h-5 mr-3 text-gray-600" />
                {{ $t('customers.time_logs.download_price') }}
              </sw-dropdown-item>
              <sw-dropdown-item @click="generateInvoice">
                <arrow-circle-down-icon class="h-5 mr-3 text-gray-600" />
                generate invoice
              </sw-dropdown-item>
              <sw-dropdown-item @click="generateInvoiceFromSelected">
                <arrow-circle-down-icon class="h-5 mr-3 text-gray-600" />
                generate invoice from selected
              </sw-dropdown-item>
              <sw-dropdown-item @click="setSelectedTimeLogsAsPaid">
                <arrow-circle-down-icon class="h-5 mr-3 text-gray-600" />
                Set selected as paid
              </sw-dropdown-item>
            </sw-dropdown>
          </div>
        </div>

      </div>
    </div>

    <div class="grid grid-cols-12">
      <div class="col-span-12 xl:col-span-9 xxl:col-span-10">
        <all-projects-chart
          :projects="getChartProjects"
          :labels="getChartMonths"
          class="sm:w-full"
        />
      </div>

      <div
        class="grid col-span-12 mt-6 text-center xl:mt-0 sm:grid-cols-4 xl:text-right xl:col-span-3 xl:grid-cols-1 xxl:col-span-2"
      >
        <div class="px-6 py-2">
          <span class="text-xs leading-5 lg:text-sm">
            {{ $t('projects.due_hours') }}
          </span>
          <br />
          <span class="block mt-1 text-xl font-semibold leading-8">
            <div v-html="getFormattedTotalHoursDue" />
          </span>
        </div>
        <div class="px-6 py-2">
          <span class="text-xs leading-5 lg:text-sm">
            {{ $t('projects.due_income') }}
          </span>
          <br />
          <span
            class="block mt-1 text-xl font-semibold leading-8"
            style="color: #5851d8"
          >
            <div v-html="getFormattedTotalPriceDue" />
          </span>
        </div>
        <div class="px-6 py-2">
          <span class="text-xs leading-5 lg:text-sm">
            {{ $t('projects.total_hours') }}
          </span>
          <br />
          <span
            class="block mt-1 text-xl font-semibold leading-8"
          >
            <div v-html="getFormattedTotalHours" />
          </span>
        </div>
        <div class="px-6 py-2">
          <span class="text-xs leading-5 lg:text-sm">
            {{ $t('projects.total_income') }}
          </span>
          <br />
          <span
            class="block mt-1 text-xl font-semibold leading-8"
            style="color: #5851d8"
          >
            <div v-html="getFormattedTotalPrice" />
          </span>
        </div>
      </div>
    </div>

    <div>
      <sw-checkbox
        v-model="selectAllFieldStatus"
        variant="primary"
        size="sm"
        class="hidden md:inline"
        @change="selectAllTimeLogs"
      />

      <sw-checkbox
        v-model="selectAllFieldStatus"
        :label="$t('general.select_all')"
        variant="primary"
        size="sm"
        class="md:hidden"
        @change="selectAllTimeLogs"
      />

      <p>Selected hours: {{getSelectedHours}}</p>
      <p>Selected price: {{getSelectedPrice}}</p>
    </div>


    <sw-table-component
      v-if="getTimeLogs.length > 0"
      ref="table"
      :show-filter="false"
      :data="getTimeLogs"
      table-class="table"
    >
      <sw-table-column
        :sortable="false"
        :filterable="false"
        cell-class="no-click"
      >
        <div class="relative block" slot-scope="row">
          <sw-checkbox
            :id="row.id"
            v-model="selectField"
            :value="row.id"
            variant="primary"
            size="sm"
          />
        </div>
      </sw-table-column>
      <sw-table-column
        :sortable="true"
        :filterable="true"
        :label="'#'"
      >
        <template slot-scope="row">
          <span>#</span>
          <a
            @click="openTimeLogModal(row)"
            class="font-medium text-primary-500"
          >
            {{ row.id }}
          </a>
        </template>
      </sw-table-column>
      <sw-table-column
        :sortable="true"
        :filterable="true"
        :label="$t('customers.time_logs.project')"
        show="project"
      >
        <template slot-scope="row">
          <span>{{ $t('customers.time_logs.project') }}</span>
          {{ row.project.name }}
        </template>
      </sw-table-column>
      <sw-table-column
        :sortable="true"
        :filterable="true"
        :label="$t('customers.time_logs.date')"
      >
        <template slot-scope="row">
          <span>{{ $t('customers.time_logs.date') }}</span>
          {{ row.formattedStartDate }}
        </template>
      </sw-table-column>
      <sw-table-column
        :sortable="true"
        :filterable="true"
        :label="$t('customers.time_logs.hours')"
      >
        <template slot-scope="row">
          <span>{{ $t('customers.time_logs.hours') }}</span>
          {{ row.hours }} h
        </template>
      </sw-table-column>

    </sw-table-component>

  </sw-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ChartSquareBarIcon, DotsHorizontalIcon, ArrowCircleDownIcon } from '@vue-hero-icons/outline'
import AllProjectsChart from '../../../components/chartjs/AllProjectsChart'

export default {
  components: {
    ChartSquareBarIcon,
    DotsHorizontalIcon,
    ArrowCircleDownIcon,
    AllProjectsChart
  },
  data() {
    return {
      timeLogs: [],
      customer: null,
      customFields: [],
      selectedTimeLogs: [],
      selectAllField: false
    }
  },
  computed: {
    ...mapGetters('customer', ['selectedViewCustomer']),


    selectField: {
      get: function() {
        return this.selectedTimeLogs
      },
      set: function(val) {
        this.selectedTimeLogs = val
      }
    },
    selectAllFieldStatus: {
      get: function() {
        return this.selectAllField
      },
      set: function(val) {


      }
    },

    getSelectedHours(){
      if(!this.selectedTimeLogs.length){
        return 0;
      }

      return this.selectedTimeLogs.map(x => {
        const log = this.getTimeLogs.find(y => y.id === x);
        return log.hours;
      }).reduce((a,b) => a + b);
    },
    getSelectedPrice(){
      if(!this.selectedTimeLogs.length){
        return 0;
      }

      return this.selectedTimeLogs.map(x => {
        const log = this.getTimeLogs.find(y => y.id === x);
        return log.hours * log.hourly_rate;
      }).reduce((a,b) => a + b);
    },

    getTimeLogs() {
      if (this.selectedViewCustomer.customer && this.selectedViewCustomer.customer.due_time_logs) {
        return this.selectedViewCustomer.customer.due_time_logs
      }

      return []
    },

    getChartProjects() {
      if (this.selectedViewCustomer.chartData && this.selectedViewCustomer.chartData.hoursTotals) {
        return this.selectedViewCustomer.chartData.hoursTotals
      }
      return []
    },

    getFormattedTotalHoursDue() {
      if (this.selectedViewCustomer.chartData && this.selectedViewCustomer.chartData.hoursDueTotal) {
        return this.selectedViewCustomer.chartData.hoursDueTotal
      }
      return 0
    },

    getFormattedTotalHours() {
      if (this.selectedViewCustomer.chartData && this.selectedViewCustomer.chartData.hoursTotal) {
        return this.selectedViewCustomer.chartData.hoursTotal
      }
      return []
    },


    getFormattedTotalPriceDue() {
      if (this.selectedViewCustomer.chartData && this.selectedViewCustomer.chartData.priceDueTotal) {
        return this.$utils.formatMoney(
          this.selectedViewCustomer.chartData.priceDueTotal,
          this.defaultCurrency
        )
      }
      return 0
    },

    getFormattedTotalPrice() {
      if (this.selectedViewCustomer.chartData && this.selectedViewCustomer.chartData.priceTotal) {
        return this.$utils.formatMoney(
          this.selectedViewCustomer.chartData.priceTotal,
          this.defaultCurrency
        )
      }
      return 0
    },


    getChartMonths() {
      if (this.selectedViewCustomer.chartData && this.selectedViewCustomer.chartData.months) {
        return this.selectedViewCustomer.chartData.months
      }
      return []
    }
  },
  watch: {
    $route(to, from) {
      this.customer = this.selectedViewCustomer
    }
  },
  methods: {
    ...mapActions('modal', ['openModal']),
    ...mapActions('customer', [
      'fetchViewCustomer',
      'generateInvoiceFromDueTimeLogs',
      'generateInvoiceFromSelectedTimeLogs',
      'markSelectedTimeLogsPaid'
    ]),
    selectTimeLog(log) {
      this.selectedTimeLogs = log
    },
    selectAllTimeLogs(val) {
      if(val){
        this.selectedTimeLogs = this.getTimeLogs.map(x => x.id);
        this.selectAllField = true;
      }else{
        this.selectedTimeLogs = [];
        this.selectAllField = false;
      }
    },
    downloadTimeSheet(withPrice) {
      window.location.href = `/api/v1/customers/${this.selectedViewCustomer.customer.id}/time-sheet?withPrice=${withPrice ? 1 : 0}`
    },

    async generateInvoice() {

      const response = await this.generateInvoiceFromDueTimeLogs({ id: this.selectedViewCustomer.customer.id })
      const invoice = response.data.invoice
      window.toastr['success'](this.$tc('invoices.created_message'))

      this.$router.push(`/admin/invoices/${invoice.id}/edit`)
    },

    async generateInvoiceFromSelected() {

      const response = await this.generateInvoiceFromSelectedTimeLogs({
        id: this.selectedViewCustomer.customer.id,
        timeLogs: this.selectedTimeLogs
      })
      const invoice = response.data.invoice
      window.toastr['success'](this.$tc('invoices.created_message'))

      this.$router.push(`/admin/invoices/${invoice.id}/edit`)
    },

    async setSelectedTimeLogsAsPaid() {

      const response = await this.markSelectedTimeLogsPaid({
        id: this.selectedViewCustomer.customer.id,
        timeLogs: this.selectedTimeLogs
      })
      window.toastr['success']('Time logs have been marked as paid');
    },
    openTimeLogModal(timeLog) {
      this.openModal({
        title: this.$t('projects.time_logs.new_time_log'),
        componentName: 'TimeLogModal',
        id: timeLog.id,
        data: timeLog,
        refreshData: this.refreshData
      })
    },
    refreshData() {
      this.fetchViewCustomer({ id: this.$route.params.id })
    }
  }

}
</script>

import * as types from './mutation-types'

export default {
  [types.BOOTSTRAP_TIME_LOGS](state, timeLogs) {
    state.timeLogs = timeLogs
  },

  [types.SET_TOTAL_TIME_LOGS](state, totalTimeLogs) {
    state.totalTimeLogs = totalTimeLogs
  },

  [types.ADD_TIME_LOG](state, data) {
    state.timeLogs.push(data.timeLog)
  },

  [types.UPDATE_TIME_LOG](state, data) {
    let pos = state.timeLogs.findIndex((timeLog) => timeLog.id === data.timeLog.id)

    state.timeLogs[pos] = data.timeLog
  },

  [types.DELETE_TIME_LOG](state, id) {
    let index = state.timeLogs.findIndex((timeLog) => timeLog.id === id[0])
    state.timeLogs.splice(index, 1)
  },

  [types.DELETE_MULTIPLE_TIME_LOGS](state, selectedTimeLogs) {
    selectedTimeLogs.forEach((timeLog) => {
      let index = state.timeLogs.findIndex((_timeLog) => _timeLog.id === timeLog.id)
      state.timeLogs.splice(index, 1)
    })

    state.selectedTimeLogs = []
  },

  [types.SET_SELECTED_TIME_LOGS](state, data) {
    state.selectedTimeLogs = data
  },

  [types.SET_SELECT_ALL_STATE](state, data) {
    state.selectAllField = data
  },

  [types.SET_SELECTED_VIEW_TIME_LOG](state, selectedViewTimeLog) {
    state.selectedViewTimeLog = selectedViewTimeLog
  },
}
